<template>
  <div class="outfit-list">
    <div class="container">
      <p class="no-outfit-hint" v-show="isEmpty">
        沒有穿搭紀錄<br/>
        請從<router-link :to="{name:'OutfitCalendar'}">穿搭日曆</router-link>中新增穿搭紀錄
      </p>

      <section class="outfit-date-section" v-for="(item, index) in outfitsByDate" :key="item.date + '_' + index">
        <div class="px-1">
          <p class="outfit-date-title">{{ __parseDateString(item.date) }}</p>
          <p class="outfit-amount"><img :src="require('@/assets/icons/cloth-icon.png')" class="cloth-icon"/> {{ item.outfits.length }} 組</p>
        </div>
        
        <div class="row no-gutters">
          <div class="col-4 px-1 mb-2" v-for="(outfit) in item.outfits" :key="outfit.serial">
            <a href="#" class="d-block" @click.prevent="outfitClicked(outfit)">
              <OutfitBlock :outfit="outfit"></OutfitBlock>
            </a>

          </div>
        </div>
      </section>

    </div>

    <transition name="fade">
      <ViewOutfit v-if="isInfoModalShow" :id="focusSerial" @back="closeInfoModal" @edit="openEditModal" @deleted="outfitDeleted" can-edit can-delete></ViewOutfit>
    </transition>

    <transition name="fade">
      <CreateOutfit v-if="isEditModalShow" :id="focusSerial" @back="closeEditOutfitModal" @updated="outfitUpdated"></CreateOutfit>
    </transition>
  </div>
</template>


<script>
import {mapActions, mapGetters, mapState} from 'vuex';
import moment from 'moment';
import ViewOutfit from '@/views/closet/ViewOutfit.vue';
import OutfitBlock from '@/components/outfit/OutfitBlock.vue';
import CreateOutfit from '@/views/closet/CreateOutfit.vue';

export default {
  name: 'OutfitList',
  data() {
    return {
      init: false,

      outfits: [],
      readingBusyName: 'READOUFITSBUSY',
      isInfoModalShow: false,
      isEditModalShow: false,
      focusSerial: -1,

      sortRule: 0,   // 0: 新到舊, 1: 穿多到穿少, 2: 穿少到穿多, 3: 價低到價高, 4: 價高到價低
      parseCloths: [],

      toSelectName: '',
      toSelectCloths: [],

      focusClothSerial: -1,
      showViewCloth: false,
      showEditCloth: false,
      showSelectCloth: false,

      listFilter: {},
      isFilterModalShow: false,
      isSortModalShow: false,
    };
  },
  created() {
  },
  props: {
  },
  components: {
    ViewOutfit,
    OutfitBlock,
    CreateOutfit,
  },
  computed: {
    ...mapState(['cloths', 'clothCategories']),
    ...mapGetters('outfit', ['isOutfitManagerShow']),

    outfitsByDate() {
      const sortedOutfitList = [... this.outfits].sort((a, b) => {
        return new Date(b.date).getTime() - new Date(a.date).getTime();
      });
      const res = sortedOutfitList.reduce((ary, item) => {
        const d = item.date.substring(0, 7);
        for (const o of ary) {
          if (o.date === d) {
            o.outfits.push(item);
            return ary;
          }
        }
        ary.push({
          date: d,
          outfits: [
            item,
          ]
        });
        return ary;
      }, []);

      return res.sort((a, b) => {
        return new Date(b.date).getTime() - new Date(a.date).getTime();
      });
    },
    isEmpty() {
      return this.outfits.length === 0;
    }
	},
  beforeDestroy() {
  },
  watch: {
    isOutfitManagerShow(newVal) {
      if (!newVal) {
        this.__refresh();
      }
    }
  },
  async mounted() {
    await this.__refresh();
    this.init = true;
	},
  methods: {
    ...mapActions('outfit', ['setOutfitId', 'setOutfitType', 'setOutfitData', 'clearOutfitData', 'openOutfitManager']),
    ...mapActions(['showMsgModal', 'appendComponentBusy', 'clearComponentBusy', 'appendErrorMsg', 'updateCloth']),
    async __refresh() {
      this.appendComponentBusy(this.readingBusyName);
      try {
        const list = await this.$store.dispatch('api/readOutfitListPromise');
        this.outfits.splice(0, this.outfits.length);
        for (const outfit of list) {
          this.outfits.push(outfit);
        }
      } catch(err) {
        this.appendErrorMsg(err.toString());
      } finally {
        this.clearComponentBusy(this.readingBusyName);
      }
    },
    __parseDateString(d) {
      return moment(d+'-01').format('YYYY.MM');
    },
    outfitClicked(outfit) {
      this.clearOutfitData();
      this.setOutfitData({
        outfitId: outfit.serial,
        outfitType: 'view',
      });
      this.openOutfitManager();
      // this.focusSerial = outfit.serial;
      // this.isInfoModalShow = true;
    },
    closeInfoModal() {
      this.isInfoModalShow = false;
    },
    openEditModal() {
      this.isEditModalShow = true;
    },
    closeEditOutfitModal() {
      this.isEditModalShow = false;
    },
    outfitDeleted(outfit) {
      for (let i=0;i<this.outfits.length;i++) {
        if (this.outfits[i].serial === outfit.serial) {
          this.outfits.splice(i, 1);
          break;
        }
      }
      this.closeInfoModal();
    },
    outfitUpdated(outfit) {
      for (let i=0;i<this.outfits.length;i++) {
        if (this.outfits[i].serial === outfit.serial) {
          this.$set(this.outfits, i, outfit);
          break;
        }
      }
      this.closeEditOutfitModal();
      this.closeInfoModal();
    },
  }
}
</script>

<style scoped>
  .outfit-list {
  }

  .outfit-date-section {
    margin-bottom: 1rem;
  }

  .outfit-date-title {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 0;
  }

  .outfit-amount {
    font-size: .75rem;
    color: #8D8D8D;
    margin-bottom: .5em;
  }

  .cloth-icon {
    width: 1em;
    height: 1em;
    object-fit: contain;
  }

  .no-outfit-hint {
    text-align: center;
    padding: 32px 16px 0;
    line-height: 3em;
  }
</style>
