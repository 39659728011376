<template>
  <div class="outfits-list">
    <div class="list-nav">
      <div class="search-btn" @click="toggleSearchFilterModal('open')">
        <Icon name="search" size="24" />
      </div>
      <CustomSearchFilterModal v-model="showSearchFilterModal" :filterData="filterData" @updateFilterData="updateFilterData" @close="toggleSearchFilterModal('close')" />
      <div class="nav-item-list">
        <div class="nav-item fs-14px fw-bold" :class="{ active: currentClass === item }" v-for="(item, index) in outfitsNavClass" :key="index" @click="filterOutfits(item)">
          <span v-text="item"></span>
          <span v-if="situationCounts[item]">({{ situationCounts[item] }})</span>
        </div>
      </div>
    </div>
    <div class="list-content">
      <div class="content-group" v-for="group in groupedOutfits" :key="`${group.year}-${group.month}`">
        <h3 class="fs-h3">{{ group.year }} 年 {{ parseInt(group.month, 10) }} 月</h3>
        <div class="group-list">
          <div class="group-item" :class="getSizeClass(outfit.size)" v-for="outfit in group.outfits"
               :key="outfit.id">
            <div class="outfits-item" @click.prevent="outfitClicked(outfit)">
              <OutfitImage :outfit="outfit"></OutfitImage>
              <Icon class="private-icon" name="lock" size="24" v-if="!outfit.isPublic" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex';
import moment from "moment";
import OutfitImage from "@/components/outfit/OutfitImage.vue";
import CustomSearchFilterModal from "@/components/commons/CustomSearchFilterModal.vue";

export default {
  name: 'OutfitsList',
  data() {
    return {
      init: false,
      readingBusyName: 'readOutfitsListBusy',

      outfitsNavClass: ['全部', '上班', '約會', '運動', '上課', '逛街', '聚會', '婚禮', '其他'],
      currentClass: '全部',
      showSearchFilterModal: false,

      filterData: {
        searchText: '',
        feeling: null,
        isPublic: null,
        sortDirection: 'desc',
      },
    };
  },
  created() {
  },
  props: {
    outfits: {
      type: Array,
      required: true,
    },
  },
  components: {
    OutfitImage, CustomSearchFilterModal,
  },
  computed: {
    ...mapState(['cloths', 'clothCategories']),
    ...mapGetters('outfit', ['isOutfitManagerShow']),

    situationCounts() {
      const counts = this.outfits.reduce((acc, outfit) => {
        const situation = outfit.situation; // 假設每個 outfit 都有 situation
        if (!acc[situation]) acc[situation] = 0;
        acc[situation]++;
        return acc;
      }, {});

      // "全部" 顯示 outfits 總數
      counts['全部'] = this.outfits.length;

      return counts;
    },
    groupedOutfits() {
      const result = [];

      // 根據 currentClass 過濾 outfits
      const currentClassOutfits = this.currentClass === '全部'
          ? this.outfits
          : this.outfits.filter(outfit => outfit.situation === this.currentClass);

      // 根據 filterData 過濾資料
      let filteredOutfits = currentClassOutfits.slice();
      const { searchText, feeling, isPublic, sortDirection } = this.filterData;
      /// 搜尋過濾
      if (searchText) {
        const search = searchText.toLowerCase();
        filteredOutfits = filteredOutfits.filter(({ place, desc }) =>
            [place, desc].some(field => field?.toLowerCase().includes(search))
        );
      }
      /// 心情過濾
      if (feeling !== null) {
        filteredOutfits = filteredOutfits.filter(({ feelingUnknown, feeling: outfitFeeling }) =>
            !feelingUnknown && outfitFeeling === feeling
        );
      }
      /// 公開狀態過濾
      if (isPublic !== null) {
        filteredOutfits = filteredOutfits.filter(outfit => outfit.isPublic === isPublic);
      }
      /// 根據 outfit.date 排序，'desc' 新的在前, 'asc' 舊的在前
      filteredOutfits = filteredOutfits.sort((a, b) =>
          sortDirection === 'asc'
              ? moment(a.date).valueOf() - moment(b.date).valueOf()
              : moment(b.date).valueOf() - moment(a.date).valueOf()
      );

      // 根據年份和月份分組 outfits
      const grouped = filteredOutfits.reduce((acc, item) => {
        const [year, month] = item.date.split('-');

        if (!acc[year]) acc[year] = {};
        if (!acc[year][month]) acc[year][month] = [];

        acc[year][month].push(item);
        return acc;
      }, {});

      for (const year in grouped) {
        for (const month in grouped[year]) {
          // 在這裡將月份的資料經過 assignSizes 增加 size
          grouped[year][month] = this.assignSizes(grouped[year][month]);

          result.push({
            year,
            month,
            outfits: grouped[year][month]
          });
        }
      }

      return result;
    },
	},
  beforeDestroy() {
  },
  watch: {
  },
  async mounted() {
    this.init = true;
	},
  methods: {
    ...mapActions('outfit', ['setOutfitId', 'setOutfitType', 'setOutfitData', 'clearOutfitData', 'openOutfitManager']),
    ...mapActions(['showMsgModal', 'appendComponentBusy', 'clearComponentBusy', 'appendErrorMsg', 'updateCloth']),

    filterOutfits(situation) {
      this.currentClass = situation;

      // 重新刷新資料
      this.$emit('refresh')
    },

    assignSizes(items) {
      const totalItems = items.length;
      const groupSize = 3;

      // 取得商數和餘數
      const quotient = Math.floor(totalItems / groupSize);
      const isOddQuotient = quotient % 2;
      const remainder = totalItems % groupSize;

      // 按照規則分配 item.size
      let pattern = ['1x1', '1x1', '1x1', '2x2', '1x1', '1x1', '1x1', '1x1', '1x1', '1x1', '1x1', '1x1', '1x1', '2x2', '1x1'];
      let patternIndex = 0;

      for (let i = 0; i < totalItems; i++) {
        items[i].size = pattern[patternIndex];

        // 更新 patternIndex 並確保在 15 的範圍內循環
        patternIndex = (patternIndex + 1) % pattern.length;
      }

      if (remainder === 1) {
        items[totalItems - 1].size = isOddQuotient ? '3x3' : '3x2';
      } else if (remainder === 2) {
        items[totalItems - 2].size = isOddQuotient ? '2x1' : '1x1';
        items[totalItems - 1].size = isOddQuotient ? '1x1' : '2x1';
      }

      return items;
    },

    getSizeClass(size) {
      return `size-${size}`;
    },

    outfitClicked(outfit) {
      this.clearOutfitData();
      this.setOutfitData({
        outfitId: outfit.serial,
        outfitType: 'view',
      });
      this.openOutfitManager();
    },

    toggleSearchFilterModal(action) {
      switch(action) {
        case 'close':
          this.showSearchFilterModal = false;
          break;
        case 'open':
          this.showSearchFilterModal = true;
          break;
        default:
          this.showSearchFilterModal = !this.showSearchFilterModal;
      }
    },

    updateFilterData(data) {
      this.filterData = { ...data };
    },
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/basic";
.outfits-list {
  .list-nav {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    gap: .625rem;
    max-width: 100%;
    overflow: auto;
    .search-btn {
      position: sticky;
      left: 0;
      z-index: 1;
      cursor: pointer;
      display: flex;
      color: $typography-secondary-default;
      background: $color-white;
      padding: .75rem;
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 3rem;
        background: linear-gradient(to right, $color-white, transparent);;
        width: .625rem;
        height: 100%;
      }
    }
    .nav-item-list {
      z-index: 0;
      display: flex;
      flex-wrap: nowrap;
      gap: 1.5rem;
    }
    .nav-item {
      position: relative;
      display: inline-block;
      flex: 0 0 auto;
      padding: .25rem;
      &.active::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        background: $typography-interactive-primary;
        width: calc(100% - .75rem);
        height: 2px;
        transform: translateX(-50%);
      }
    }
  }
  .list-content {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 1rem;
    .content-group {
      display: flex;
      flex-direction: column;
      gap: .5rem;
      h3.fs-h3 {
        margin: 0;
      }
    }
    .group-list {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: .5rem;
    }
    .group-item {
      position: relative;
      width: 100%;
      padding-top: 100%;
      &.size-2x1 {
        grid-column: span 2;
        grid-row: span 1;
        padding-top: calc((100% - 1rem) / 3); /* 高度維持 2:1 */
      }
      &.size-2x2 {
        grid-column: span 2;
        grid-row: span 2;
      }
      &.size-3x2 {
        grid-column: span 3;
        grid-row: span 2;
        padding-top: calc((100% - 1rem) / 3 * 2 + 0.5rem); /* 高度維持 3:2 */
      }
      &.size-3x3 {
        grid-column: span 3;
        grid-row: span 3;
      }
    }
    .outfits-item {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: .5rem;
      overflow: hidden;
      .private-icon {
        position: absolute;
        top: .5rem;
        right: .5rem;
        color: $color-input-grey;
        filter: drop-shadow(0 0 1px rgba(0, 0, 0, .4));
      }
    }
  }
}
</style>
<style lang="scss">
@import "src/assets/scss/basic";
.outfits-list {
  .custom-search-filter-modal {

  }
}
</style>
