<template>
  <div class="custom-search-filter-modal">
    <transition name="fade">
      <div v-if="value" class="backdrop" @click="backClicked"></div>
    </transition>
    <transition name="slide">
      <div v-if="value" class="modal-content">
        <div class="search-filter-nav">
          <button class="close-btn" @click="backClicked">
            <Icon name="chevron-left" size="24" />
          </button>
          <button class="save-btn fs-18px fw-bold" @click="updateFilterData">
            <span>確認</span>
          </button>
        </div>
        <div class="search-filter-option-panel">
          <div class="option-block">
            <div class="fs-20px fw-bold">搜尋</div>
            <label class="search-group">
              <Icon name="search" size="24" />
              <b-form-input
                  class="fs-16px"
                  v-model="inputFilterData.searchText"
                  type="text"
                  placeholder="請搜尋地點或備註"
              />
              <span class="clear-btn fs-16px" @click="clearSearchText">清除全部</span>
            </label>
          </div>
          <div class="option-block filter-group">
            <div class="fs-20px fw-bold">篩選</div>
            <div class="feeling-settings">
              <label :class="{ picked: inputFilterData.feeling === 1 }">
                <Icon name="emoji-happy" size="30" />
                <input type="radio" :value="1" v-model="inputFilterData.feeling">
              </label>
              <label :class="{ picked: inputFilterData.feeling === 0 }">
                <Icon name="emoji-fine" size="30" />
                <input type="radio" :value="0" v-model="inputFilterData.feeling">
              </label>
              <label :class="{ picked: inputFilterData.feeling === -1 }">
                <Icon name="emoji-sad" size="30" />
                <input type="radio" :value="-1" v-model="inputFilterData.feeling">
              </label>
            </div>
            <div class="privacy-settings">
              <label :class="{ picked: inputFilterData.isPublic === true }">
                <Icon class="check-icon" name="check" size="24" />
                <span class="fs-14px">公開穿搭</span>
                <Icon name="unlock" size="20" />
                <input type="radio" :value="true" v-model="inputFilterData.isPublic">
              </label>
              <label :class="{ picked: inputFilterData.isPublic === false }">
                <Icon class="check-icon" name="check" size="24" />
                <span class="fs-14px">私人穿搭</span>
                <Icon name="lock" size="20" />
                <input type="radio" :value="false" v-model="inputFilterData.isPublic">
              </label>
            </div>
            <div class="clear-btn" @click="clearFilterSettings">清除條件</div>
          </div>
          <div class="option-block">
            <div class="fs-20px fw-bold">排序方式</div>
            <div class="sort-settings">
              <div class="sort-desc">
                <Icon class="check-icon" name="check" size="24" />
                <span>依穿搭新增時間（預設）</span>
              </div>
              <div class="sort-group">
                <label :class="{ picked: inputFilterData.sortDirection === 'asc' }">
                  <Icon name="arrow-up" size="24" />
                  <input type="radio" value="asc" v-model="inputFilterData.sortDirection">
                </label>
                <label :class="{ picked: inputFilterData.sortDirection === 'desc' }">
                  <Icon name="arrow-down" size="24" />
                  <input type="radio" value="desc" v-model="inputFilterData.sortDirection">
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'CustomSearchFilterModal',
  data() {
    return {
      inputFilterData: {
        searchText: '',
        feeling: null,
        isPublic: null,
        sortDirection: 'desc',
      },
    }
  },
  components: {
  },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    filterData: {
      type: Object,
      default: () => ({
        searchText: '',
      }),
    },
  },
  watch: {
    value(newValue) {
      if(newValue) {
        this.inputFilterData = { ...this.filterData };
      }
    },
  },
  computed: {
  },
  mounted() {
  },
  methods: {
    backClicked() {
      this.$emit('close');
    },
    clearSearchText() {
      this.inputFilterData.searchText = '';
    },
    clearFilterSettings() {
      this.inputFilterData.feeling = null;
      this.inputFilterData.isPublic = null;
    },
    updateFilterData() {
      this.$emit('updateFilterData', this.inputFilterData);
      this.$emit('close');
    },
  },
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/basic";
.custom-search-filter-modal {
  .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9000;
    width: 100%;
    height: var(--unit-100vh);
    background: $color-bk-tp-60;
  }
  .modal-content {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 9000;
    width: 100%;
    color: $icon-color-selection;
    background: $color-white;
    border-radius: 1rem 1rem 0 0;
    .header.fs-h3 {
      text-align: center;
      margin-bottom: 2rem;
    }
  }
  .search-filter-nav {
    position: relative;
    text-align: center;
    padding: 1rem 4rem;
    .close-btn {
      position: absolute;
      top: .5rem;
      left: .5rem;
      display: flex;
      background: transparent;
      border: none;
      padding: .5rem;
    }
    .save-btn {
      position: absolute;
      top: .5rem;
      right: .5rem;
      color: $typography-text-link;
      background: transparent;
      line-height: 24px;
      border: none;
      padding: .5rem;
    }
  }
  .search-filter-option-panel {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1.25rem 1rem 4rem;
    .option-block {
      display: flex;
      flex-direction: column;
      gap: .5rem;
    }
    .search-group {
      display: flex;
      align-items: center;
      gap: .5rem;
      background: $color-image-background;
      border-radius: .5rem;
      padding: 1rem;
      margin-bottom: 0;
      svg, span {
        flex: 0 0 auto;
      }
      .clear-btn {
        color: $color-input-grey;
      }
    }
    .filter-group {
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      padding-bottom: .25rem;
      .clear-btn {
        cursor: pointer;
        position: absolute;
        top: 0;
        right: 0;
        color: $icon-color-selection;
        font-weight: 500;
        padding: .25rem;
      }
      .feeling-settings, .privacy-settings {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        gap: 1rem;
        label {
          flex: 0 0 auto;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 100px;
          margin-bottom: 0;
          input {
            display: none;
          }
        }
      }
      .feeling-settings {
        label {
          color: $typography-secondary-default;
          width: 48px;
          height: 48px;
          border: 1px solid $color-border;
          &.picked {
            color: $color-white;
            background: $icon-color-selection;
            border-color: $icon-color-selection;
          }
        }
      }
      .privacy-settings {
        label {
          display: flex;
          align-items: center;
          gap: .25rem;
          color: $color-input-grey;
          border: 1px solid $color-input-grey;
          padding: .375rem 1rem;
          .check-icon {
            display: none;
          }
          &.picked {
            color: $icon-color-selection;
            border: 2px solid $icon-color-selection;
            .check-icon {
              display: block;
            }
          }
        }
      }
    }
    .sort-settings {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: nowrap;
      border-bottom: solid 1px $color-border;
      padding: .5rem .375rem .375rem 0;
    }
    .sort-desc {
      display: flex;
      flex-wrap: nowrap;
      gap: .5rem;
      color: $color-input-grey;
      .check-icon {
        color: $icon-color-selection;
      }
    }
    .sort-group {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      gap: .5rem;
      label {
        flex: 0 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $color-input-grey;
        width: 2rem;
        height: 2rem;
        border: 1px solid $color-border;
        border-radius: .5rem;
        margin-bottom: 0;
        &.picked {
          color: $color-white;
          background: $color-input-grey;
          border-color: $color-input-grey;
        }
        input {
          display: none;
        }
      }
    }
  }
}
.slide-enter-active, .slide-leave-active {
  transition: transform 0.5s ease;
}
.slide-enter, .slide-leave-to {
  transform: translateY(100%);
}
</style>
<style lang="scss">
@import "src/assets/scss/basic";
.custom-search-filter-modal {
  .search-group {
    input.form-control {
      flex-grow: 1;
      background: none;
      border: none;
      padding: 0;
      &::placeholder {
        color: $color-btn-disabled-secondary;
      }
      &:focus {
        box-shadow: none;
      }
    }
  }
}
</style>