<template>
  <div class="outfit-image">
    <div class="none-image" v-if="loaded">
      <BIcon icon="card-image"></BIcon>
    </div>
    <transition name="fade">
      <img :src="outfitImg" v-if="!loaded" @load="imgLoaded" @error="onError" alt="outfit"/>
    </transition>
  </div>
</template>


<script>

export default {
  name: 'OutfitImage',
  data() {
    return {
      resolvedOutfitImg: null,
      loaded: false,
    };
  },
  props: {
    outfit: {
      type: Object,
      required: true,
    },
  },
  components: {
  },
  computed: {
    outfitImg() {
      // return this.outfit.outfitPhotos[0].imgUrl;
      return this.resolvedOutfitImg || '';
    },
	},
  watch: {
    outfit: {
      handler: 'resolveOutfitImg',
      deep: true, // 確保監聽內部變化
      immediate: true, // 組件掛載時也執行一次
    }
  },
  mounted() {
	},
  methods: {
    async resolveOutfitImg() {
      if (this.outfit.outfitPhotos.length === 0) return;

      const firstPhoto = this.outfit.outfitPhotos[0];
      this.loaded = true;

      if (firstPhoto.imgUrl) {
        this.loaded = false
        this.resolvedOutfitImg = firstPhoto.imgUrl;
      } else if (firstPhoto.clothSerial) {
        try {
          const res = await this.$store.dispatch('api/readClothInfoPromise', firstPhoto.clothSerial);
          this.resolvedOutfitImg = res.imgs?.[0] || ''; // 確保 res.imgs[0] 存在，否則為空字串
          this.loaded = false;
        } catch (e) {
          console.error('獲取 cloth 圖片失敗:', e);
        }
      }
    },
    imgLoaded() {
      this.$emit('loaded');
    },
    onError() {
      console.error("圖片載入失敗:", this.src);
    },
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/basic";
.outfit-image {
  width: 100%;
  height: 100%;
  background: $color-image-background;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .none-image {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $typography-secondary-default;
    width: 100%;
    height: 100%;
    font-size: 32px;
    span {
      display: block;
      flex: 0 0 auto;
    }
  }
}
</style>
