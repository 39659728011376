<template>
  <div class="outfits-index">
    <div class="header">
      <div class="block">
        <div class="card outfit-today">
          <template v-if="hasOutfitToday">
            <div class="title">
              <Icon name="icon-outfit-border" size="24" />
              <h3 class="fs-h3">今日穿搭</h3>
            </div>
            <OutfitImage :outfit="outfits[0]" />
          </template>

          <template v-else>
            <Icon name="icon-outfit-border" size="24" />
            <h3 class="fs-h3">今日穿搭</h3>
            <div class="desc fs-12px fw-bold">今天還沒紀錄穿搭喔！</div>
          </template>
        </div>
      </div>
      <div class="block">
        <div class="card outfit-info">
          <div class="outfit-calendar-btn">
            <span class="fs-16px fw-bold">穿搭日曆</span>
            <Icon name="chevron-right" size="24" />
          </div>
<!--          <span class="fs-14px fw-bold">coming soon...</span>-->
          <b-carousel
            class="outfits-msg-group"
            :interval="5000"
            indicators
          >
            <b-carousel-slide>
              <template #img>
                <div class="msg-box">
                  <div class="flag">
                    <div class="fs-14px">
                      <span>已經連續紀錄</span>
                    </div>
                    <div class="count-days">
                      <h1 class="fs-h1">79</h1>
                      <span class="fs-16px">天</span>
                    </div>
                  </div>
                  <div class="fs-12px fw-bold">超過 90% 的使用者</div>
                </div>
              </template>
            </b-carousel-slide>
            <b-carousel-slide>
              <template #img>
                <div class="msg-box">
                  <div class="flag">
                    <div class="fs-14px">
                      <span>心情紀錄</span>
                    </div>
                    <div class="count-days">
                      <h1 class="fs-h1">79</h1>
                      <span class="fs-16px">天</span>
                    </div>
                  </div>
                  <div class="fs-12px fw-bold">有助提高app建議精準度</div>
                </div>
              </template>
            </b-carousel-slide>
          </b-carousel>
        </div>
      </div>
    </div>
    <outfitsList :outfits="outfits" @refresh="__refresh" />
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex';
import moment from 'moment';
import outfitsList from "@/views/closet/OutfitsList.vue";
import OutfitImage from "@/components/outfit/OutfitImage.vue";
import {FALSE} from "sass";

export default {
  name: 'OutfitsIndex',
  data() {
    return {
      init: false,
      readingBusyName: 'readOutfitsIndexBusy',

      outfits: [],
    };
  },
  created() {
  },
  props: {
  },
  components: {
    outfitsList, OutfitImage,
  },
  computed: {
    FALSE() {
      return FALSE
    },
    ...mapState(['cloths', 'clothCategories']),
    ...mapGetters('outfit', ['isOutfitManagerShow']),

    outfitsByDate() {
      const sortedOutfitList = [... this.outfits].sort((a, b) => {
        return new Date(b.date).getTime() - new Date(a.date).getTime();
      });
      const res = sortedOutfitList.reduce((ary, item) => {
        const d = item.date.substring(0, 7);
        for (const o of ary) {
          if (o.date === d) {
            o.outfits.push(item);
            return ary;
          }
        }
        ary.push({
          date: d,
          outfits: [
            item,
          ]
        });
        return ary;
      }, []);

      return res.sort((a, b) => {
        return new Date(b.date).getTime() - new Date(a.date).getTime();
      });
    },
    isEmpty() {
      return this.outfits.length === 0;
    },
    hasOutfitToday() {
      if (!this.outfits.length || !this.outfits[0].date) {
        return false;
      }
      return moment(this.outfits[0].date).isSame(moment(), 'day');
    },
	},
  beforeDestroy() {
  },
  watch: {
  },
  async mounted() {
    await this.__refresh();
    this.init = true;
	},
  methods: {
    ...mapActions('outfit', ['setOutfitId', 'setOutfitType', 'setOutfitData', 'clearOutfitData', 'openOutfitManager']),
    ...mapActions(['showMsgModal', 'appendComponentBusy', 'clearComponentBusy', 'appendErrorMsg', 'updateCloth']),
    async __refresh() {
      this.appendComponentBusy(this.readingBusyName);
      try {
        const list = await this.$store.dispatch('api/readOutfitListPromise');
        this.outfits.splice(0, this.outfits.length);
        for (const outfit of list) {
          this.outfits.push(outfit);
        }
      } catch(err) {
        this.appendErrorMsg(err.toString());
      } finally {
        this.clearComponentBusy(this.readingBusyName);
      }
    },
    __parseDateString(d) {
      return moment(d+'-01').format('YYYY.MM');
    },
    outfitClicked(outfit) {
      this.clearOutfitData();
      this.setOutfitData({
        outfitId: outfit.serial,
        outfitType: 'view',
      });
      this.openOutfitManager();
      // this.focusSerial = outfit.serial;
      // this.isInfoModalShow = true;
    },
    closeInfoModal() {
      this.isInfoModalShow = false;
    },
    openEditModal() {
      this.isEditModalShow = true;
    },
    closeEditOutfitModal() {
      this.isEditModalShow = false;
    },
    outfitDeleted(outfit) {
      for (let i=0;i<this.outfits.length;i++) {
        if (this.outfits[i].serial === outfit.serial) {
          this.outfits.splice(i, 1);
          break;
        }
      }
      this.closeInfoModal();
    },
    outfitUpdated(outfit) {
      for (let i=0;i<this.outfits.length;i++) {
        if (this.outfits[i].serial === outfit.serial) {
          this.$set(this.outfits, i, outfit);
          break;
        }
      }
      this.closeEditOutfitModal();
      this.closeInfoModal();
    },
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/basic";
.outfits-index {
  position: relative;
  .header {
    display: flex;
    gap: .625rem;
    padding: 1rem 1rem 2rem;
    .block {
      position: relative;
      width: calc((100% - .75rem)/2);
      padding-top: calc((100% - .75rem)/2);
      .card {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        border: solid 1px $color-border;
        border-radius: .75rem;
        &.outfit-today {
          gap: .5rem;
          .title {
            display: flex;
            flex-wrap: nowrap;
            gap: .5rem;
          }
          h3.fs-h3 {
            margin: 0;
          }
          .outfit-image {
            width: 58%;
            height: 58%;
            border-radius: .5rem;
            overflow: hidden;
          }
        }
        &.outfit-info {
          justify-content: space-between;
          flex-direction: column;
          gap: .75rem;
          padding: 1rem;
          .outfits-msg-group {
            width: 100%;
            height: calc(100% - 2rem);
          }
          .msg-box {
            display: flex;
            flex-direction: column;
            gap: .5rem;
            color: $typography-secondary-default;
            width: 100%;
            height: 100%;
            .flag {
              .count-days {
                display: flex;
                align-items: center;
                gap: .375rem;
                h1.fs-h1 {
                  color: $typography-interactive-primary;
                  line-height: 2.5rem;
                }
              }
            }
            h1.fs-h1 {
              margin-bottom: 0;
            }
          }
        }
      }
      .outfit-calendar-btn {
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: nowrap;
        width: 100%;
      }
    }
  }
  .desc {
    color: $typography-secondary-default;
  }
}
</style>
<style lang="scss">
@import "src/assets/scss/basic";
.outfits-index {
  .outfits-msg-group {
    .carousel-inner {
      //height: calc(100% - 1rem);
      height: 100%;
      .carousel-item {
        height: 100%;
      }
    }
    .carousel-indicators {
      margin-bottom: 0;
      li {
        background-color: $typography-disabled;
        width: .25rem;
        height: .25rem;
        border-radius: 100px;
        border: none;
        //border-top: solid .75rem transparent;
        //border-bottom: 0;
        opacity: 1;
        &.active {
          background-color: $typography-secondary-default;
        }
      }
    }
  }
}
</style>
